console.group("inicializando o pack js vendor")

import 'jquery';
import 'jquery-toast-plugin';
import 'jquery.rateit';
import 'remotipart';
import 'air-datepicker';
import Animator from 'animejs';
import DragSelect from 'dragselect';
import Roman from 'romanjs';
import Picker from 'vanilla-picker';
import Vibrant from 'node-vibrant';
import Croppie from 'croppie';
import PhotoSwipe from 'photoswipe';
import Calandar from 'js-year-calendar';
import 'js-year-calendar/locales/js-year-calendar.pt';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import Rails from '@rails/ujs';
import tippy from 'tippy.js';
import Freewall from 'freewall';

Rails.start();





import '/var/www/apps/obj/ruby/app/frontend/vendor/js/chart_wrapper.js';

import '/var/www/apps/obj/ruby/app/frontend/vendor/js/datepicker_overrides.js';

import '/var/www/apps/obj/ruby/app/frontend/vendor/js/dragula_wrapper.js';

import '/var/www/apps/obj/ruby/app/frontend/vendor/js/fcalendar.js';

import '/var/www/apps/obj/ruby/app/frontend/vendor/js/foundation.js';

import '/var/www/apps/obj/ruby/app/frontend/vendor/js/iv-viewer.js';

import '/var/www/apps/obj/ruby/app/frontend/vendor/js/jquery_masked_input_plugin.min.js';

import '/var/www/apps/obj/ruby/app/frontend/vendor/js/lvt-banner.js';

import '/var/www/apps/obj/ruby/app/frontend/vendor/js/lvt-dialog.js';

import '/var/www/apps/obj/ruby/app/frontend/vendor/js/lvt-doughnut.js';

import '/var/www/apps/obj/ruby/app/frontend/vendor/js/lvt-empty_dialog.js';

import '/var/www/apps/obj/ruby/app/frontend/vendor/js/lvt-kanitz.js';

import '/var/www/apps/obj/ruby/app/frontend/vendor/js/masonry.pkgd.min.js';

import '/var/www/apps/obj/ruby/app/frontend/vendor/js/rails-action-text.js';




import FullScreenViewer from './iv-viewer.js';
import Kanitz from './lvt-kanitz.js';
import Dialog from './lvt-dialog.js';
import Doughnut from './lvt-doughnut.js';
import Banner from './lvt-banner.js';
import EmptyDialog from './lvt-empty_dialog.js';

global.Rails = Rails
global.anime = Animator
global.FullScreenViewer = FullScreenViewer
global.Kanitz = Kanitz
global.Banner = Banner
global.Dialog = Dialog
global.Dialog = Dialog
global.EmptyDialog = EmptyDialog
global.DragSelect = DragSelect
global.Roman = Roman
global.Picker = Picker
global.Vibrant = Vibrant
global.Croppie = Croppie
global.PhotoSwipe = PhotoSwipe
global.PhotoSwipeUI_Default = PhotoSwipeUI_Default
global.Calendar = Calandar
global.tippy = tippy
global.Freewall = Freewall.Freewall

tippy.setDefaultProps({
    placement: 'left-start',
    animation: 'shift-away',
    arrow: true,
    allowHTML: true,
    theme: 'app_theme',
    touch: ['hold', 300]
});

console.groupEnd()